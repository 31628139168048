/**
 * 示例-自定义组件-业务弹窗
 * luxinwen
 * 2023-02
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <div class="page-main-content-thead">modal-test 业务弹窗</div>
        <div class="page-main-content-subhead">示例</div>
        <Card class="page-main-content-item">
          <Button type="primary" @click="showModal('Basic')">基本用法</Button>
          <Divider orientation="left" plain>基本用法</Divider>
          <pre>&lt;modal-test v-model="value" @submit="submitModal"&gt;&lt;/modal-test&gt;</pre>
          <p>业务弹窗的基本用法。每次仅选择一条数据。</p>
        </Card>
        <Card class="page-main-content-item">
          <Button type="primary" @click="showModal('Multiple')">可多选</Button>
          <Divider orientation="left" plain>可多选</Divider>
          <pre>&lt;modal-test v-model="value" multiple @submit="submitModal"&gt;&lt;/modal-test&gt;</pre>
          <p>设置属性 <span class="text-red">multiple</span>，可以同时选择多条数据。</p>
        </Card>
        <Card class="page-main-content-item">
          <Button type="primary" @click="showModal('Selected')">已选择</Button>
          <Divider orientation="left" plain>默认已选择</Divider>
          <pre>&lt;modal-test v-model="value" :selected="selectedList" @submit="submitModal"&gt;&lt;/modal-test&gt;</pre>
          <p>设置属性 <span class="text-red">selected</span>，通过传入数组可显示已选择的数据。</p>
        </Card>
        <Card class="page-main-content-item">
          <Button type="primary" @click="showModal('Disabled')">禁止选择</Button>
          <Divider orientation="left" plain>禁止选择</Divider>
          <pre>&lt;modal-test v-model="value" :disabled="disabledList" @submit="submitModal"&gt;&lt;/modal-test&gt;</pre>
          <p>设置属性 <span class="text-red">disabled</span>，通过传入数组可控制禁止选择的数据。</p>
        </Card>
        <div class="page-main-content-subhead">Props</div>
        <Table :columns="propsColumns" :data="propsData" border />
        <div class="page-main-content-subhead">Events</div>
        <Table :columns="eventsColumns" :data="eventsData" border />
      </div>
    </div>

    <modal-test v-model="value" :multiple="multiple" :selected="selectedList" :disabled="disabledList" @submit="submitModal"></modal-test>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        params: {
          'value': {
            tips: '是否显示弹窗，可以使用 v-model 双向绑定数据',
            type: 'Boolean',
            default: 'false'
          },
          'multiple': {
            tips: '是否支持选择多条数据',
            type: 'Boolean',
            default: 'false'
          },
          'selected': {
            tips: '已选择的数据',
            type: 'Array',
            default: '[]'
          },
          'disabled': {
            tips: '禁止选择的数据',
            type: 'Array',
            default: '[]'
          }
        },
        events: {
          'submit': {
            tips: '点击选取按钮或确定按钮时就会触发',
            return: 'selection：已选项数据'
          }
        },
        propsColumns: [
          {
            title: '属性',
            key: 'name',
            width: 140
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '类型',
            key: 'type',
            width: 140
          },
          {
            title: '默认值',
            key: 'default',
            width: 160
          }
        ],
        eventsColumns: [
          {
            title: '事件名',
            key: 'name',
            width: 250
          },
          {
            title: '说明',
            key: 'tips'
          },
          {
            title: '返回值',
            render: (h, params) => {
              let children = [];
              params.row.return.split('；').forEach(item => {
                let p = [];
                item.split('：').forEach((key, index) => {
                  p.push(h('span', {
                    attrs: {
                      class: index === 0 ? 'text-red' : ''
                    }
                  }, key));
                });
                if (p.length === 2) {
                  p.splice(1, 0, h('span', '：'));
                }
                children.push(h('p', p));
              });
              return h('div', children);
            }
          }
        ],
        value: false,
        multiple: false,
        selectedList: [],
        disabledList: []
      };
    },
    computed: {
      propsData() {
        let val = [];
        let data = this.params;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          item.default = item.default || '-';
          val.push(item);
        });
        return val;
      },
      eventsData() {
        let val = [];
        let data = this.events;
        Object.keys(data).forEach(key => {
          let item = Object.assign({}, data[key], {
            name: key
          });
          val.push(item);
        });
        return val;
      }
    },
    methods: {
      /**
       * 查看弹窗
       */
      showModal(type) {
        if (type === 'Multiple') {
          this.multiple = true;
          this.selectedList = [];
          this.disabledList = [];
        } else if (type === 'Selected') {
          this.multiple = true;
          this.selectedList = [{
            id: 1,
            name: 'lxw'
          }];
          this.disabledList = [];
        } else if (type === 'Disabled') {
          this.selectedList = [];
          this.disabledList = [{
            id: 3
          }];
        } else {
          this.multiple = false;
          this.selectedList = [];
          this.disabledList = [];
        }
        this.value = true;
      },
      /**
       * 确定选择
       */
      submitModal(selection) {
        console.log('---', selection);
      }
    }
  };
</script>